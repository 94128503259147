import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black-charcoal-gradient py-6 text-gray-400 text-center">
      <div className="container mx-auto">
        <ul className="flex justify-center space-x-6 mb-6">
          <li>
            <Link to="/faq" className="hover:text-white  :text-white underline">
              FAQ
            </Link>
          </li>
          <li>
            <Link to="/terms" className="hover:text-white underline">
              Terms of Service
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="hover:text-white underline">
              Privacy Policy
            </Link>
          </li>
        </ul>
        <p className="text-sm">&copy; 2024 Vercee. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
