import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WelcomeScreen from "../components/WelcomeScreen";
import LoadingScreen from "../components/LoadingScreen";

const ExplorePage = ({ userAttributes }) => {
  const [showWelcome, setShowWelcome] = useState(false);
  const [loading, setLoading] = useState(true);

  const mockVideos = [
    { id: 1, title: "Live Concert 1", thumbnail: "/path/to/thumbnail1.jpg", description: "Artist's concert" },
    { id: 2, title: "Exclusive Session 2", thumbnail: "/path/to/thumbnail2.jpg", description: "Exclusive content" },
    { id: 3, title: "Music Video 3", thumbnail: "/path/to/thumbnail3.jpg", description: "New release" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      // Simulate API/data fetching
      await new Promise((resolve) => setTimeout(resolve, 500)); // Simulating a delay
      setLoading(false); // Loading complete
    };

    fetchData();

    // Check if the user has visited the Explore page before
    const hasVisited = localStorage.getItem("hasVisitedExplore");
    if (!hasVisited) {
      setShowWelcome(true); // Show welcome screen on first visit
      localStorage.setItem("hasVisitedExplore", "true"); // Mark as visited
    }
  }, []);

  const handleWelcomeFinish = () => {
    setShowWelcome(false); // Hide welcome screen after the delay
  };

  return (
    <div className="w-screen min-w-[320px] bg-gradient p-8 relative">
      {loading && <LoadingScreen />}
      {!loading && (
        <>
          {/* Main Explore Page Content */}
          <header className="flex flex-col md:flex-row justify-between items-center mb-6 pt-4 px-8">
            {/* Placeholder for future Categories Section */}
          </header>

          {/* Featured Content Section */}
          <section className="mb-8">
            <Link to="/subscribe" className="text-white underline btn btn-ghost w-full normal-case text-left px-4 py-2">
              Subscription Plans
            </Link>
            <Link
              to="/video-player-setup/ZOOM!+MUSIC+VIDEO"
              className="text-white underline btn btn-ghost w-full normal-case text-left px-4 py-2"
            >
              Video Player Test
            </Link>
            <h2 className="text-2xl font-semibold mb-4">Featured Content</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="bg-gray-100 p-4 rounded-md shadow-md">
                <img
                  src="/path/to/featured-thumbnail.jpg"
                  alt="Featured Content"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold">Live Performance by Artist X</h3>
              </div>
            </div>
          </section>

          {/* Video Previews Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Video Previews</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {mockVideos.map((video) => (
                <div key={video.id} className="bg-white p-4 rounded-md shadow-md">
                  <img src={video.thumbnail} alt={video.title} className="w-full h-48 object-cover rounded-md mb-4" />
                  <h3 className="text-xl font-semibold">{video.title}</h3>
                  <p className="text-gray-600">{video.description}</p>
                </div>
              ))}
            </div>
          </section>
        </>
      )}

      {/* Overlay Welcome Screen only if showWelcome is true */}
      {!loading && showWelcome && <WelcomeScreen userAttributes={userAttributes} onFinish={handleWelcomeFinish} />}
    </div>
  );
};

export default ExplorePage;
