import React from "react";

const WatchPage = () => {
  return (
    <div className="watch-page-container flex flex-col items-center">
      <header className="text-4xl font-bold decoration-und">Watch Page</header>
    </div>
  );
};

export default WatchPage;
