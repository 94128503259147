import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, useAuthenticator } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { faHelmetSafety } from "@fortawesome/free-solid-svg-icons";
import { faDownLong } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../components/Modals/InfoModal";

const HomePage = () => {
  const modalRef = useRef(null);
  // const [showInfoModal, setShowInfoModal] = useState(false);
  // const [infoSectionNumber, setInfoSectionNumber] = useState(null);
  const navigate = useNavigate();
  const { user, isLoading } = useAuthenticator((context) => [context.user, context.isLoading]);

  // Redirect to /explore if user is logged in
  useEffect(() => {
    if (!isLoading && user) {
      navigate("/explore");
    }
  }, [isLoading, user, navigate]);

  // const handleCTAClick = () => {
  //   setShowLoginModal(true);
  // };

  // Handle closing the info modal
  // const handleCloseInfoModal = useCallback(() => {
  //   setShowInfoModal(false);
  // }, [setShowInfoModal]);

  // Redirect to mail.html when "JOIN NOW" is clicked
  // const handleJoinNowClick = () => {
  //   window.location.href = "/mailing.html";
  // };

  //sends info modal the correct section number to display info based on what section was clicked
  // const openInfoModal = (sectionNumber) => {
  //   setShowInfoModal(true);
  //   setInfoSectionNumber(sectionNumber);
  // };

  return (
    <div className="home-page-container bg-transparent text-white items-start w-screen">
      <div className="caution-tape-container hidden md:contents">
        <div class="fixed bg-caution-stripes top-0 right-0 transform rotate-45 translate-x-20 translate-y-20 w-96 h-12 text-black font-bold text-sm flex items-center justify-center shadow-lg z-50">
          <span class="tracking-wide bg-[#facc15] text-black w-96 text-center text-nowrap">
            - UNDER CONSTRUCTION - UNDER CONSTRUCTION - UNDER CONSTRUCTION -
          </span>
        </div>
      </div>
      {/* subscription details section */}
      <section
        style={
          {
            // backgroundImage: "url('/subbg.jpg')",
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
            // minWidth: "150px",
            // minHeight: "fit-content"
          }
        }
        className="bg-transparent subscription-section text-center !h-fit flex flex-col justify-around items-center sm:h-[520px] md:h-[540px] lg:h-[560px] xl:h-[580px] 2xl:h-[600px] 3xl:h-[620px] wide:h-[100vh] small-wide:h-[100vh]"
      >
        <div className="cards-container flex flex-row pt-16 rounded-3xl justify-around items-center space-x-2 mt-4 w-full p-4 sm:w-[90vw] sm:space-x-4 md:space-x-8 xl:space-x-12">
          {/* <div className="card-one-container w-1/2 h-full hover-glow-large rounded-3xl">
            <Card
              className="!bg-deepPurple !bg-opacity-75 !h-full !w-full !flex !flex-col !items-center !justify-between !rounded-3xl !p-0"
              onClick={handleCTAClick}
            >
              <div className="sub-info-top-container h-full w-full flex flex-col align-middle rounded-3xl">
                <h2 className="text-lg text-hotPink bg-white rounded-t-xl min-h-8 sm:text-2xl md:text-3xl">Basic</h2>
                <h2 className="text-shadow text-lg bg-deepPurple p-[.5rem] sm:text-2xl md:text-3xl small-wide:text-sm">
                  $19.99/mo
                </h2>
                <ul className="w-fit p-4 h-full min-h-[180px] self-center flex flex-col justify-start text-xs font-normal align-middle wide:min-h-0 sm:text-sm md:text-md lg:text-lg">
                  <li className="text-left pb-3">
                    &bull; Access to <strong>ALL</strong> live Shows
                  </li>
                  <li className="text-left pb-3">
                    &bull; Access to <strong>ALL</strong> recordings
                  </li>
                  <li className="text-left pb-3">
                    &bull; <strong>SD</strong> streaming
                  </li>
                </ul>
              </div>
              <div className="w-full relative top-1">
                <Button className="!text-xs !h-12 !border-none !w-full !rounded-full" onClick={handleCTAClick}>
                  GET BASIC
                </Button>
              </div>
            </Card>
          </div> */}
          {/* <div className="card-two-container w-1/2 h-full hover-glow-large rounded-3xl">
            <Card
              className="!bg-deepPurple !bg-opacity-75 !h-full !w-full !flex !flex-col !items-center !justify-between !rounded-3xl !p-0"
              onClick={handleCTAClick}
            >
              <div className="sub-info-top-container h-full w-full flex flex-col align-middle">
                <h2 className="text-lg text-hotPink bg-white rounded-t-xl min-h-8 sm:text-2xl md:text-3xl">Premium</h2>
                <h2 className="text-shadow text-lg p-[.5rem] bg-deepPurple sm:text-2xl md:text-3xl small-wide:text-sm">
                  $27.99/mo
                </h2>
                <ul className="w-fit p-4 h-full self-center min-h-[180px] flex flex-col justify-start text-xs font-normal align-middle wide:min-h-0 small-wide:text-xs sm:text-sm md:text-md lg:text-lg">
                  <li className="text-left pb-3">
                    &bull; <strong>ALL</strong> basic subscription features
                  </li>
                  <li className="text-left pb-3">
                    &bull; Access to <strong>Exclusive Content</strong>
                  </li>
                  <li className="text-left pb-3">
                    &bull; <strong>HD</strong> streaming
                  </li>
                  <li className="text-left pb-3">&bull; Priority Support</li>
                </ul>
              </div>
              <div className="w-full relative top-1">
                <Button className="!text-xs !h-12 !border-none !w-full !rounded-full" onClick={handleCTAClick}>
                  GET PREMIUM
                </Button>
              </div>
            </Card>
          </div>
        </div> */}
          <div className="mailing-subscription-cta-container p-px bg-gradient rounded">
            <div className="bg-radial-charcoal-gradient rounded `rounded-[calc(2rem-1px)]`">
              <div className="sub-info-top-container h-full w-full flex flex-col align-middle">
                <div className="text-sm text-transparent bg-radial-gradient bg-clip-text flex flex-row h-full w-full p-4 align-middle justify-center items-center min-h-8 sm:text-xl md:text-3xl">
                  <div className="under-construction-container flex flex-row align-middle justify-center items-center w-fit h-fit p-4">
                    <FontAwesomeIcon className="fa-2xl text-white" icon={faWrench} />
                    <FontAwesomeIcon className="fa-2xl text-white" icon={faHelmetSafety} />
                  </div>
                  Our site is currently under construction. Exciting updates are on the way—stay tuned!
                </div>
                <h2 className="text-sm w-full bg-transparent md:text-2xl lg:text-3xl small-wide:text-sm bg-gradient p-4">
                  In The Meantime, Check Out Our Mailing List
                </h2>
                <ul className="w-fit p-4 h-full self-center min-h-fit flex flex-col justify-start text-xs font-normal align-middle wide:min-h-0 small-wide:text-xs sm:text-sm md:text-md lg:text-lg">
                  <li className="text-left pb-3">
                    &bull; Be the <strong>First to Know</strong> When We Launch
                  </li>
                  <li className="text-left pb-3">
                    &bull; Receive <strong>Updates</strong> About Our Progress
                  </li>
                  <li className="text-left pb-3">
                    &bull; <strong> Sneak Peeks</strong> At New Features
                  </li>
                </ul>
                <p className="text-center py-3 self-center lg:text-4xl underline">Join The Movement Today!</p>
                <FontAwesomeIcon icon={faDownLong} className="text-[50px] pb-4" />
              </div>
              <div className="w-full relative top-1">
                {/* <Button className="!text-xs !h-12 !border-none !w-full !rounded-full" onClick={handleJoinNowClick}>
                  JOIN NOW
                </Button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="free-cta-container flex-row justify-center w-full hidden">
          {/* <Button
            className="faded-button !underline !text-xs !h-1/2 !w-3/4 !font-bold !border-none !rounded-none !min-h-12"
            style={{
              background:
                "linear-gradient(to right, rgba(28, 6, 55, 0) 0%, rgba(28, 6, 55, 0) 10%, #7519E5 30%, #7519E5 70%, rgba(28, 6, 55, 0) 90%, rgba(28, 6, 55, 0) 100%)"
            }}
            onClick={handleCTAClick}
          >
            SIGN UP FOR A FREE ACCOUNT
          </Button> */}
        </div>
        <div
          id="mc_embed_shell"
          className="drop-shadow-2xl mailchimp-subscription-container flex flex-row rounded-3xl justify-center items-center w-full sm:space-x-4 md:space-x-8 xl:space-x-12 my-4 p-4 max-w-[800px]"
        >
          <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
          <div id="mc_embed_signup" className="!bg-reverse-gradient p-px rounded w-full !m-0">
            <div className="bg-radial-charcoal-gradient p-4 rounded `rounded-[calc(1.5rem-1px)]`">
              <form
                action={process.env.REACT_APP_MAILCHIMP_REDIRECT}
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
              >
                <div id="mc_embed_signup_scroll">
                  <h2>Subscribe</h2>
                  <div className="indicates-required">
                    <span className="asterisk">*</span> indicates required
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input type="email" name="EMAIL" className="required email text-black" id="mce-EMAIL" required />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-FNAME">First Name</label>
                    <input type="text" name="FNAME" className="text text-black" id="mce-FNAME" />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                    <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                  </div>
                  <div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
                    <input type="text" name="b_dd3d1839201c1a4a5eb6e6675_70e554be95" tabIndex="-1" />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button !bg-rose"
                      value="Subscribe"
                    />
                    <p className="pt-4">
                      By subscribing, you consent to receive informational and promotional newsletters, agree to our{" "}
                      <a
                        className="underline"
                        href="https://vercee.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms
                      </a>
                      , and acknowledge our{" "}
                      <a
                        className="underline"
                        href="https://vercee.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `
          (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0] = "EMAIL";
            ftypes[0] = "email";
            fnames[1] = "FNAME";
            ftypes[1] = "text";
            fnames[2] = "LNAME";
            ftypes[2] = "text";
            fnames[3] = "ADDRESS";
            ftypes[3] = "address";
            fnames[4] = "PHONE";
            ftypes[4] = "phone";
          })(jQuery);
          var $mcj = jQuery.noConflict(true);
        `
              }}
            ></script>
          </div>
        </div>
      </section>

      <div className="hero-sections-container w-full flex flex-col justify-center p-4 max-w-[1500px] mx-auto">
        {/* Hero Section */}
        <section className="hero-section h-full text-center bg-gradient p-px rounded">
          <div className="bg-radial-charcoal-gradient p-10 rounded `rounded-[calc(1.5rem-1px)]`">
            <header className="bg-transparent p-4 flex flex-row lg:p-8">
              <div className="vercee-logo-container hidden md:flex justify-center">
                <img src="/verceelogo512.svg" alt="vercee-logo" width="65%" className="" />
              </div>
              <div className="experience-container flex flex-col justify-center">
                <h2 className="text-2xl font-bold underline sm:text-2xl xl:text-3xl 2xl:text-4xl bg-radial-gradient-small text-transparent bg-clip-text">
                  Experience Live Music Like Never Before
                </h2>
                <p className="w-3/4 self-center text-sm pt-4 md:text-base xl:text-lg 2xl:text-xl">
                  Bringing unique, high-quality live performances to your screen. Join the future of live-streamed
                  concerts with Vercee, featuring exclusive artist performances and virtual meet-and-greets.
                </p>
              </div>
            </header>

            <div className="preview-links-outer-container w-full">
              <div className="previews-container flex flex-col items-center w-full justify-center space-y-16 pb-12 xl:flex-row xl:space-y-0 xl:space-x-24 xl:pt-0">
                {/* First Preview */}
                <div className="preview-ppv relative w-[200px] lg:w-[500px] p-px bg-reverse-gradient rounded">
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 p-10 rounded">
                    <p className="text-white text-[80px] xl:text-[190px]  font-extrabold tracking-tight uppercase">
                      PPV's
                    </p>
                  </div>
                  <div className="bg-radial-charcoal-gradient rounded aspect-video" to="/preview/1">
                    <img
                      src="/rock-show.jpg"
                      alt="image-of-rock-show-link-to-ppv-preview"
                      className="object-cover h-full w-full rounded"
                    />
                  </div>
                </div>

                {/* Second Preview */}
                <div className="preview-vod relative w-[200px] lg:w-[500px] p-px bg-reverse-gradient rounded">
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 p-10 rounded">
                    <p className="text-white text-[80px] xl:text-[190px] font-extrabold tracking-tight uppercase">
                      VOD's
                    </p>
                  </div>
                  <div className="bg-radial-charcoal-gradient rounded aspect-video" to="/preview/2">
                    <img
                      src="/stage-performer.jpg"
                      alt="image-of-stage-performer-link-to-video-on-demand-preview"
                      className="object-cover h-full w-full rounded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Hero Section 2 */}
      <section className="hero-section h-fit w-full flex flex-col justify-center text-center bg-reverse-gradient mt-4">
        <header className="bg-gradient p-4 flex flex-row lg:p-8">
          <div className="experience-container flex flex-col justify-center">
            <h2 className="text-2xl font-bold underline sm:text-2xl xl:text-3xl 2xl:text-4xl">
              Unlock Exclusive Access to the Artists You Love
            </h2>
            <p className="w-3/4 self-center text-sm pt-4 md:text-base xl:text-lg 2xl:text-xl">
              Dive deeper into your favorite music with behind-the-scenes content, live Q&As, and personal interactions
              that bring you closer to the action.
            </p>
          </div>
          <div className="vercee-logo-container hidden md:flex justify-center">
            <img src="/verceelogo512-white.png" alt="vercee-logo" width="50%" className="z-50" />
          </div>
        </header>
      </section>

      {/* Features Section */}
      <section className="pt-12 px-4 bg-transparent flex flex-col justify-center items-center" id="features">
        <div className="container text-center">
          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8">
            <div
              // onClick={() => openInfoModal(1)}
              className="bg-gradient shadow-md rounded p-px flex-1 hover-glow cursor-pointer"
            >
              <div className="bg-radial-charcoal-gradient p-10 rounded `rounded-[calc(1.5rem-1px)]`">
                <h4 className="text-xl font-semibold mb-2 underline bg-radial-gradient-small text-transparent bg-clip-text">
                  Exclusive Concerts
                </h4>
                <p className="text-white">
                  Watch live-streamed concerts from your favorite artists, available only on Vercee.
                </p>
              </div>
            </div>
            <div
              // onClick={() => openInfoModal(2)}
              className="bg-gradient shadow-md rounded p-px flex-1 hover-glow cursor-pointer"
            >
              <div className="bg-radial-charcoal-gradient p-10 rounded `rounded-[calc(1.5rem-1px)]`">
                <h4 className="text-xl font-semibold mb-2 underline bg-radial-gradient-small text-transparent bg-clip-text">
                  On-Demand Replays
                </h4>
                <p className="text-white">Missed the live stream? Subscribe and watch on-demand replays anytime.</p>
              </div>
            </div>
            <div
              // onClick={() => openInfoModal(3)}
              className="bg-gradient shadow-md rounded p-px flex-1 hover-glow cursor-pointer"
            >
              <div className="bg-radial-charcoal-gradient p-10 rounded `rounded-[calc(1.5rem-1px)]`">
                <h4 className="text-xl font-semibold mb-2 underline bg-radial-gradient-small text-transparent bg-clip-text">
                  Virtual Meet-and-Greets
                </h4>
                <p className="text-white">
                  Get closer to your favorite artists with exclusive virtual meet-and-greet sessions.
                </p>
              </div>
            </div>

            {/* <InfoModal
              // handleCloseInfoModal={handleCloseInfoModal}
              // infoSectionNumber={infoSectionNumber}
              modalRef={modalRef}
            /> */}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-transparent py-8 text-white text-center w-full" id="cta">
        <div className="container">
          {/* <h3 className="text-3xl font-bold mb-4 large-text-shadow">Explore Vercee Today!</h3>
          <p className="text-lg mb-8">
            Be part of the revolution in live music streaming. Sign up and never miss a moment!
          </p> */}
          {/* <Button
            onClick={handleCTAClick}
            className="bottom-cta-button !text-xs !h-12 !w-1/4 !border-none !rounded-full !min-w-28"
          >
            Get Started
          </Button> */}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
