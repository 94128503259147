import React from "react";
import "./loadingScreen.css"; // Custom styling

const LoadingScreen = () => {
  return (
    <div className="loading-screen bg-gradient">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingScreen;
