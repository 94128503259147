import React from "react";

const VideoPreviewPage = (videoId) => {
  return (
    <div>
      <div className="video-preview-page-container flex flex-col items-center">
        <header className="text-4xl font-bold decoration-und">Video Preview Page</header>
      </div>
      <h1>Pay-Per-View Video</h1>
      <p>Video ID: {videoId}</p>
      {/* use videoId to fetch the relevant video data */}
    </div>
  );
};

export default VideoPreviewPage;
