import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signOutUser } from "../APICalls/auth";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import LoginModal from "./Modals/LoginModal";
import SearchBar from "./SearchBar";

const Navbar = ({ showLoginModal, setShowLoginModal }) => {
  const modalRef = useRef(null);
  const dropdownRef = useRef(null);
  const { user } = useAuthenticator((context) => [context.user]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();

  //handle opening the login modal
  const handleSignInClick = () => {
    setShowLoginModal(true);
  };

  const handleSignOut = async () => {
    await signOutUser();
    // Redirect to home or handle UI updates after sign-out
  };

    // Automatically close the login modal on successful login
    useEffect(() => {
      if (user) {
        setShowLoginModal(false);
        setIsDropdownVisible(false);
      }
    }, [user, navigate, setShowLoginModal]);

    // Handle closing the login modal
    const handleCloseModal = useCallback(() => {
      setShowLoginModal(false);
    }, [setShowLoginModal]);

    //handle opening the profile dropdown
    const toggleDropdown = () => {
      setIsDropdownVisible(!isDropdownVisible);
    };

    // Close dropdown if the user clicks outside the dropdown
    useEffect(() => {
      const handleClickOutsideDropdown = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          !event.target.closest(".dropdown-icon")
        ) {
          setIsDropdownVisible(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutsideDropdown);
      return () => {
        document.removeEventListener("mousedown", handleClickOutsideDropdown);
      };
    }, [handleCloseModal]);

    // Close modal if the user clicks outside the modal
    useEffect(() => {
      const handleClickOutsideDropdown = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          handleCloseModal();
        }
      };
      document.addEventListener("mousedown", handleClickOutsideDropdown);
      return () => {
        document.removeEventListener("mousedown", handleClickOutsideDropdown);
      };
    }, [handleCloseModal]);

        {
          /* for development use, shows the media screen size in navbar to help with UI styling */
        }
        {
          /* <div className="dev-square-container absolute left-[75%] bottom-0">
          <p className="development-square-for-sizing text-center flex flex-row justify-center w-6 h-6 text-xs bg-red-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            xsm
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-orange-800 sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            sm
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-yellow-800 sm:hidden md:flex lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            md
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-green-800 sm:hidden md:hidden lg:flex xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            lg
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-blue-800 sm:hidden md:hidden lg:hidden xl:flex 2xl:hidden 3xl:hidden wide:hidden">
            xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-indigo-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:flex 3xl:hidden wide:hidden">
            2xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-violet-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:flex wide:hidden">
            3xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-black sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:flex small-wide:hidden">
            wide
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-gray-500 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden small-wide:flex">
            small-wide
          </p>
        </div> */
        }

        return (
          <div className="navbar-container absolute top-0 left-0 w-screen h-16 flex items-center justify-between z-50 bg-black bg-transparent">
            {/* Left Section: Logo with Gradient */}
            <div className="nav-logo-container flex items-center px-4 h-full">
              <Link to="/explore" className="flex items-center text-white">
                <img src="/verceelogo512-white.png" alt="vercee-logo" width="50rem" className="" />
                <h1 className="-ml-2 px-4 w-fit text-base md:text-lg lg:text-xl xl:text-2xl rounded-r-3xl hover:underline">
                  Vercee
                </h1>
              </Link>
            </div>

            {/* Center Section: Transparent */}
            <div className="nav-center-container flex-grow h-full hidden lg:flex items-center justify-center">
              {/* SearchBar is shown when the user is logged in */}
              {user && (
                <div className="searchbar-container w-full">
                  <SearchBar />
                </div>
              )}
            </div>

            {/* Right Section: Profile Dropdown or Sign In with Gradient */}
            <div className="nav-right-container items-center px-4 h-full justify-end">
              {/* If the user is logged in, show the dropdown menu */}
              {user ? (
                <nav className="relative dropdown-container flex flex-row justify-end z-50">
                  <Link className="dropdown-icon btn btn-ghost normal-case text-xl text-white" onClick={toggleDropdown}>
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                  {/* Dropdown menu for the user */}
                  {isDropdownVisible && (
                    <div
                      className="dropdown-container flex flex-col border-8 border-deepPurple shadow-2xl shadow-black justify-between absolute right-0 top-6 mt-2 w-48 h-96 bg-white text-purple rounded-md z-50 text-sm"
                      ref={dropdownRef}
                    >
                      {/* Display the user's login ID */}
                      <h1 className="px-4 text-sm border-t-0 border-x-0 border-b-black border-4">
                        {user.signInDetails.loginId}
                      </h1>
                      <div className="dropdown-links-container h-full border-t-0 border-x-0 border-b-black border-4">
                        <ul>
                          <li>
                            <Link
                              to="/profile"
                              className="btn btn-ghost underline w-full normal-case text-left px-4 py-2"
                            >
                              <p>Profile</p>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="dropdown-extras-container bg-deepPurple h-full">
                        <ul className="flex flex-col items-end">
                          <li>
                            <Link
                              to="/help"
                              className="text-white underline btn btn-ghost w-full normal-case text-left px-4 py-2"
                            >
                              Help Center
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="text-white underline btn btn-ghost w-full normal-case text-left px-4 py-2"
                              onClick={handleSignOut}
                            >
                              Sign Out
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </nav>
              ) : (
                // Show the Sign In button if the user is not logged in
                <div className="sign-in w-full flex flex-row justify-end">
                  <button
                    onClick={handleSignInClick}
                    className="text-rose bg-deepPurple rounded-3xl px-5 py-2 hover:text-white hidden"
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>

            {/* Pass modalRef to the LoginModal */}
            {showLoginModal && <LoginModal handleCloseModal={handleCloseModal} modalRef={modalRef} />}
          </div>
        );

};

export default Navbar;
