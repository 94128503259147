import React, { useEffect } from "react";
import "./welcomeScreen.css"; // Styles for the animation

const WelcomeScreen = ({ userAttributes, onFinish }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish(); // Call onFinish to hide the welcome screen
    }, 3000); // Show for 2 seconds

    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <div className="welcome-screen w-screen z-50">
      <h1>Welcome, {userAttributes?.given_name}!</h1>
    </div>
  );
};

export default WelcomeScreen;
