import React from "react";

import DynamicBackground from "../components/DynamicBackgroundStripes";

const DesignTestPage = () => {
  return (
    <div>
      {/* Dynamic Background */}
      <DynamicBackground />
      <div
        className="design-test-page-container relative flex flex-col items-center w-screen h-screen bg-transparent"
        style={{ zIndex: 1, position: "relative" }}
      >
        <div className="mx-auto w-[400px] h-[400px] border">
          <header className="text-4xl font-bold decoration-und">Test Page</header>
          <h1>For UI and Styling</h1>
        </div>

        {/* template for gradient border */}
        <div className="p-px bg-gradient rounded">
          <div className="bg-radial-charcoal-gradient p-10 rounded-[calc(1.5rem-1px)]"></div>
        </div>
      </div>
    </div>
  );
};

export default DesignTestPage;

/*


*/
