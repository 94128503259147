import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector"; // Import the quality selector plugin

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isVideoMounted, setIsVideoMounted] = useState(false);

  useEffect(() => {
    setIsVideoMounted(true);

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isVideoMounted && videoRef.current && !playerRef.current) {
      const videoJsOptions = {
        controls: true,
        autoplay: false,
        fluid: true,
        controlBar: {
          children: [
            "playToggle", // Play/Pause button
            "volumePanel", // Volume control
            "progressControl", // Progress bar for scrubbing
            "currentTimeDisplay", // Current playback time
            "timeDivider", // Divider between current time and duration
            "durationDisplay", // Total duration of the video
            "fullscreenToggle" // Fullscreen toggle
          ]
        },
        plugins: {
          hlsQualitySelector: {} // Activate HLS quality selector
        },
        sources: [
          {
            src: "https://vercee-vod-processed.s3.us-west-2.amazonaws.com/Zoom!/ZOOM!%20MUSIC%20VIDEO.m3u8",
            type: "application/x-mpegURL"
          }
        ]
      };

      // Initialize the player and the quality selector plugin
      playerRef.current = videojs(videoRef.current, videoJsOptions, function () {
        console.log("Video.js player is ready");
        this.hlsQualitySelector(); // Activate the quality selector plugin
      });
    }
  }, [isVideoMounted]);

  return (
    <div className="video-player-container min-w-[320px] min-h-[180px] bg-black">
      {isVideoMounted && (
        <video ref={videoRef} className="video-js vjs-default-skin" controls preload="auto" width="100%" height="100%">
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
