import React from "react";

const NotFound = () => {
  return (
    <div className="not-found-page-container w-screen bg-gradient min-h-screen py-12 min-w-[320px] flex flex-col justify-center">
      <section className="text-center h-full">
        <div className="w-full py-12 h-full">
          <h1 className="text-4xl font-bold text-white text-shadow">Oops! Page Not Found</h1>
        </div>
      </section>
      <div className="mx-auto text-center">
        <p>The page you are looking for doesn't exist.</p>
      </div>
      {/* Add any other content or styling you want */}
    </div>
  );
};

export default NotFound;
