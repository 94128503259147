import React from "react";

const HelpPage = () => {
  return (
    <div className="help-page-container w-screen bg-gradient min-h-screen p-8 pt-24 min-w-[320px]">
      <section className="relative text-center">
        <div className="w-full py-12">
          <h1 className="text-4xl font-bold text-white text-shadow">Help Center</h1>
        </div>
      </section>
    </div>
  );
};

export default HelpPage;
