import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { VideoProvider } from "./components/context/VideoContext";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import ExplorePage from "./pages/ExplorePage";
import LandingPreviewPage from "./pages/LandingPreviewPage";
import SubscribePage from "./pages/SubscribePage";
import FAQPage from "./pages/FAQPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import Footer from "./components/Footer";

import DesignTestPage from "./pages/DesignTestPage";

import NotFound from "./pages/NotFound";
import HelpPage from "./pages/HelpPage";
import ProfilePage from "./pages/ProfilePage";
import WatchPage from "./pages/WatchPage";
import PPVPage from "./pages/PPVPage";
import PPVPreviewPage from "./pages/PPVPreviewPage";
import VideosPage from "./pages/VideosPage";
import VideoPreviewPage from "./pages/VideoPreviewPage";
import VideoPlayerSetup from "./pages/VideoPlayerSetup";
import DynamicBackgroundStripes from "./components/DynamicBackgroundStripes";

import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getUserAttributes } from "./APICalls/getUserAttributes";

import awsExports from "./aws-exports";

Amplify.configure(awsExports);

library.add(faBars);

function ProtectedRoute({ children }) {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);

  // Display loading screen while authentication status is still loading
  if (authStatus === "loading") {
    return <div>Loading...</div>;
  }

  // Only redirect if authentication status is definitively unauthenticated
  if (authStatus === "unauthenticated" && !user) {
    return <Navigate to="/" replace />;
  }

  // Render protected content if authenticated
  return authStatus === "authenticated" && user ? children : null;
}

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);

  // Fetch user attributes only after the user is authenticated
  useEffect(() => {
    const fetchAttributes = async () => {
      if (authStatus === "authenticated" && user) {
        const attributes = await getUserAttributes();
        setUserAttributes(attributes);
      }
    };

    fetchAttributes();
  }, [authStatus, user]); // Only run when authStatus or user changes

  return (
    <HelmetProvider>
      <DynamicBackgroundStripes />
      <Router>
        <VideoProvider>
          <div>
            {/* default title and meta description for SEO */}
            <Helmet>
              <title>Vercee - Live Music Reimagined</title>
              {/* <script id="mcjs">
                {`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],
                m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}
                (document,"script","https://chimpstatic.com/mcjs-connected/js/users/dd3d1839201c1a4a5eb6e6675/5d669a34905f87a5487503fd6.js")`}
              </script> */}
              <meta
                name="description"
                content="Join Vercee to experience live-streamed concerts and exclusive content from top artists."
              />
            </Helmet>
            <Navbar showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
            <Routes>
              {/* public routes */}
              <Route path="/" element={<HomePage setShowLoginModal={setShowLoginModal} />} />
              <Route path="/preview/:previewId" element={<LandingPreviewPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/video-player-setup/:videoId" element={<VideoPlayerSetup />} />

              {/* routes available to public for better SEO */}
              <Route path="/videos/:videoId" element={<VideoPreviewPage />} />
              <Route path="/ppv/:videoId" element={<PPVPreviewPage />} />

              {/* page for development, delete when ready for production */}
              <Route path="/design" element={<DesignTestPage />} />

              {/* protected routes */}
              <Route
                path="/explore"
                element={
                  <ProtectedRoute>
                    <ExplorePage userAttributes={userAttributes} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscribe"
                element={
                  <ProtectedRoute>
                    <SubscribePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/videos"
                element={
                  <ProtectedRoute>
                    <VideosPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ppv"
                element={
                  <ProtectedRoute>
                    <PPVPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/watch"
                element={
                  <ProtectedRoute>
                    <WatchPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </VideoProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
