import React, { useEffect, useRef } from "react";

const DynamicBackgroundStripes = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener("resize", resizeCanvas);

    // Animation variables
    const lineCount = 20; // Number of lines
    const lineSpacing = 50; // Space between lines
    const waveSpeed = 0.005; // Slower animation (reduced from 0.02 to 0.01)
    const colors = [
      "rgba(28, 6, 55, 0.8)", // deepPurple
      "rgba(117, 25, 229, 0.6)", // purple
      "rgba(157, 50, 183, 0.5)", // lightPurple
      "rgba(195, 72, 140, 0.4)", // hotPink
      "rgba(230, 94, 100, 0.3)" // rose
    ];
    let offset = 0;

    // Draw flowing lines
    const drawLines = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 2;

      for (let i = 0; i < lineCount; i++) {
        ctx.beginPath();
        ctx.strokeStyle = colors[i % colors.length];
        const y = canvas.height / 2 + i * lineSpacing - (lineCount / 2) * lineSpacing;

        for (let x = 0; x < canvas.width; x++) {
          const waveHeight = 30; // Slightly smaller wave height for elegance
          const frequency = 0.01; // Smoother curves
          const newY = y + Math.sin(x * frequency + offset + i * 0.001) * waveHeight;
          ctx.lineTo(x, newY);
        }
        ctx.stroke();
      }
      offset += waveSpeed;
    };

    // Animation loop
    const animate = () => {
      drawLines();
      requestAnimationFrame(animate);
    };

    animate();

    // Cleanup
    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        background: "linear-gradient(135deg, #010001, #000000)" // Sleek dark gradient
      }}
    />
  );
};

export default DynamicBackgroundStripes;
