import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const demoData = {
  1: {
    title: "Live from the Denver",
    artist: "Demonstration Dan",
    date: "8/11/2024",
    genre: "Rock",
    description: "Demonstration Dan takes to the stage for one legendary night of rock and roll."
  },
  2: {
    title: "One night in Rome",
    artist: "Drake",
    date: "9/27/2024",
    genre: "R&B",
    description: "Drake performs live at the Imaginary Theatre in Rome."
  }
};

const LandingPreviewPage = () => {
  const { previewId } = useParams();
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);


  //when the page loads it uses the scroll position from the landing page, this useEffect ensures the page is loaded with the scroll position at the top
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  //
  //
  //
  //
  //
  //
  //
  //
  //
  // EXAMPLE OF DATA FETCH ONCE WE HAVE A DECENT LIBRARY OF VIDEOS TO CHOOSE FROM TO HIGHLIGHT ON THE HOME PAGE

  // useEffect(() => {
  //   // Fetch data based on the previewId
  //   const fetchPreviewData = async () => {
  //     try {
  //       const response = await fetch(`/api/preview/${previewId}`);
  //       const data = await response.json();
  //       setPreviewData(data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching preview data:", error);
  //       setLoading(false);
  //     }
  //   };

  //   if (previewId) {
  //     fetchPreviewData();
  //   }
  // }, [previewId]);
  //
  //
  //
  //
  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //
  // FOR NOW WE WILL MANUALLY ENTER THE TWO VIDEO PREVIEWS UNTIL WE DECIDE WHAT WE WANT TO INSERT FOR APP RELEASE
  useEffect(() => {
    // Ensure previewId is valid before setting the state
    if (previewId) {
      // Simulate fetching data (for now, we use the demoData)
      const fetchedData = demoData[previewId];

      if (fetchedData) {
        setPreviewData(fetchedData);
      } else {
        setPreviewData(null); // Handle case where there's no matching data
      }

      setLoading(false); // Set loading to false once data is set
    }
  }, [previewId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!previewData) {
    return <div>No preview data found.</div>;
  }

  return (
    <div className="demo-video-page-container bg-gradient h-fit w-screen flex flex-col justify-start align-middle text-center min-h-screen">
      <div className="video-player-container pt-16 w-full flex justify-center">
        <div className="video-player w-screen aspect-video bg-deepPurple flex flex-col items-center justify-center sm:w-[80vw]">
          <FontAwesomeIcon icon={faVideo} />
          <p>video player</p>
        </div>
      </div>
      <div className="video-info-container text-xs text-left p-4 md:text-base lg:text-lg xl:text-2xl">
        <h1>
          {previewData.title} - {previewData.artist}
        </h1>
        <p>{previewData.date}</p>
        <p className="mb-8">{previewData.description}</p>
      </div>
      <div className="container-for-suggestions bg-transparent w-full h-fit">
        <h2 className="m-2">Suggested {previewData.genre} Videos</h2>
        {/* here we can generate more related videos based on the genre, for now were using manual entries*/}
        <div className="all-suggestions flex flex-col justify-center m-4 space-y-2">
          <div className="flex flex-row">
            <div className="w-1/2 bg-black aspect-video">1</div>
            <div className="suggestions-info w-1/2 text-xs lg:text-xl flex flex-col justify-center">
              <h1>
                {previewData.title} - {previewData.artist}
              </h1>
              <p>{previewData.date}</p>
              <p className="mb-8">{previewData.description}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/2 bg-black aspect-video">2</div>
            <div className="suggestions-info w-1/2 text-xs lg:text-xl flex flex-col justify-center">
              <h1>
                {previewData.title} - {previewData.artist}
              </h1>
              <p>{previewData.date}</p>
              <p className="mb-8">{previewData.description}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/2 bg-black aspect-video">3</div>
            <div className="suggestions-info w-1/2 text-xs lg:text-xl flex flex-col justify-center">
              <h1>
                {previewData.title} - {previewData.artist}
              </h1>
              <p>{previewData.date}</p>
              <p className="mb-8">{previewData.description}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/2 bg-black aspect-video">4</div>
            <div className="suggestions-info w-1/2 text-xs lg:text-xl flex flex-col justify-center">
              <h1>
                {previewData.title} - {previewData.artist}
              </h1>
              <p>{previewData.date}</p>
              <p className="mb-8">{previewData.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPreviewPage;
