import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ProfilePage = () => {
  const { user, isLoading } = useAuthenticator((context) => [context.user, context.isLoading]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !user) {
      navigate("/");
    }
  }, [isLoading, user, navigate]);

  return (
    <div className="profile-page-container w-screen bg-gradient min-h-screen p-8 pt-24 min-w-[320px]">
      <section className="relative text-center">
        <div className="w-full py-12">
          <h1 className="text-4xl font-bold text-white text-shadow">Profile</h1>
        </div>
      </section>
      <h1>Welcome to your profile, {user.signInDetails.loginId}</h1>
    </div>
  );
};

export default ProfilePage;
