import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const navigate = useNavigate();

  const playVideo = (videoUrl, videoPage) => {
    setCurrentVideo({ videoUrl, videoPage });
  };

  const handleExitPip = () => {
    if (currentVideo?.videoPage) {
      navigate(currentVideo.videoPage); // Redirect to the page for the video
    }
  };

  return <VideoContext.Provider value={{ currentVideo, playVideo, handleExitPip }}>{children}</VideoContext.Provider>;
};

export const useVideo = () => useContext(VideoContext);
