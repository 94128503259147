import React, { useEffect, useRef } from "react";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator, useTheme, View, Heading, Text, Button } from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "./AmplifyModalStyles.css";

Amplify.configure(awsconfig);

// Custom Components for Authenticator stages
const components = {
  Header() {
    const { tokens } = useTheme();
    return <View textAlign="center" padding={tokens.space.large}></View>;
  },

  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding="0">
        
        <Text color={tokens.colors.white}> 2024 Vercee &copy; All Rights Reserved</Text>
      </View>
    );
  },

  // Sign In Stage
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword, toSignUp } = useAuthenticator(); // Added navigation between sign-in and sign-up
      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
            Reset Password
          </Button>
          <Button fontWeight="normal" onClick={toSignUp} size="small" variation="link">
            Create Account
          </Button>
        </View>
      );
    }
  },

  // Sign Up Stage
  SignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.small} 0 0 ${tokens.space.xl}`} level={3}>
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
            Back to Sign In
          </Button>
        </View>
      );
    }
  },

  // Confirm Sign Up
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Enter Confirmation Code
        </Heading>
      );
    },
    Footer() {
      return <Text textAlign="center">Need help? Contact support.</Text>;
    }
  },

  // Forgot Password
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Reset your password
        </Heading>
      );
    }
  }
};

// Custom form fields to match your requirements
const formFields = {
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
      isRequired: true,
      order: 1
    },
    password: {
      label: "Password",
      placeholder: "Enter your password",
      isRequired: true,
      order: 2
    }
  },
  signUp: {
    given_name: {
      label: "First Name",
      placeholder: "Enter your first name",
      isRequired: true,
      order: 1
    },
    family_name: {
      label: "Last Name",
      placeholder: "Enter your last name",
      isRequired: true,
      order: 2
    },
    username: {
      label: "Username",
      placeholder: "Enter your username",
      isRequired: true,
      order: 3
    },
    password: {
      label: "Password",
      placeholder: "Enter your password",
      isRequired: true,
      order: 4
    },
    confirm_password: {
      label: "Confirm Password",
      placeholder: "Confirm your password",
      isRequired: true,
      order: 5
    }
  },
  confirmSignUp: {
    confirmation_code: {
      placeholder: "Enter the confirmation code",
      label: "Confirmation Code",
      isRequired: true
    }
  },
  forgotPassword: {
    username: {
      placeholder: "Enter your email"
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter the confirmation code"
    },
    password: {
      placeholder: "Enter your new password"
    }
  }
};

const LoginModal = ({ handleCloseModal }) => {
  const modalRef = useRef(null);
  const { user } = useAuthenticator((context) => [context.user]);

  // Close modal if user is logged in
  useEffect(() => {
    if (user) {
      handleCloseModal();
    }
  }, [user, handleCloseModal]);

  // Close modal if the user clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseModal]);

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal h-fit" ref={modalRef}>
        <Authenticator
          formFields={formFields}
          components={components}
          socialProviders={["amazon", "apple", "facebook", "google"]} // Remove if you don’t need these
          className="amplify-authenticator"
        />
      </div>
    </div>
  );
};

export default LoginModal;
