import React from "react";
import { useParams } from "react-router-dom";

const PPVPreviewPage = () => {
  const { videoId } = useParams(); // Access the dynamic videoId parameter

  return (
    <div>
      <div className="ppv-preview-page-container flex flex-col items-center">
        <header className="text-4xl font-bold decoration-und">PPV Preview Page</header>
      </div>
      <h1>Pay-Per-View Video</h1>
      <p>Video ID: {videoId}</p>
      {/* use videoId to fetch the relevant video data */}
    </div>
  );
};

export default PPVPreviewPage;
