import React from "react";

const VideosPage = () => {
  return (
    <div className="videos-page-container flex flex-col items-center">
      <header className="text-4xl font-bold decoration-und">Videos Page</header>
    </div>
  );
};

export default VideosPage;
