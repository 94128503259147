import { fetchUserAttributes } from "aws-amplify/auth";

export async function getUserAttributes() {
  try {
    const attributes = await fetchUserAttributes();
    
    console.log("attributes: ", attributes);
    return attributes;
  } catch (error) {
    console.error("Error fetching user attributes:", error);
    return null;
  }
}
