import { signIn, signUp, confirmSignUp, signOut } from "aws-amplify/auth";

// Sign-up
export const signUpUser = async ({ email, password, given_name, family_name }) => {
  return await signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name,
      family_name
    }
  });
};

// Sign-in
export const signInUser = async ({ username, password }) => {
  return await signIn(username, password);
};

// Confirm sign-up
export const confirmUserSignUp = async ({ username, confirmationCode }) => {
  return await confirmSignUp(username, confirmationCode);
};

export const signOutUser = async () => {
  try {
    await signOut();
    localStorage.removeItem("hasVisitedExplore"); // Remove to reset welcome screen
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};
