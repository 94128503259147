import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const SubscribePage = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus !== "loading" && !user) {
      navigate("/"); // Redirect to home if the user is not authenticated
    }
  }, [authStatus, user, navigate]);

  return (
    <div className="subscription-page-container w-screen bg-gradient min-h-screen p-8 pt-24 min-w-[320px]">
      <section className="relative text-center">
        <div className="w-full py-12">
          <h1 className="text-4xl font-bold text-white text-shadow">Subscription Plans</h1>
        </div>
      </section>
    </div>
  );
};

export default SubscribePage;
