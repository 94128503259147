import React, { useState } from "react";

const FAQ = () => {
  const [openId, setOpenId] = useState(null);

  const toggleAccordion = (id) => {
    if (openId === id) {
      setOpenId(null);
    } else {
      setOpenId(id);
    }
  };

  return (
    <div className="faq-page-container w-screen bg-gradient min-h-screen py-8 min-w-[320px]">
      <section className="relative text-center">
        <div className="w-full py-12">
          <h1 className="text-4xl font-bold text-white text-shadow">Frequently Asked Questions</h1>
        </div>
      </section>

      <div className="w-full max-w-[1200px] p-2 space-y-4 mx-auto">
        <div className="w-full relative text-center">
          <h2 className="text-4xl font-bold text-white text-shadow">Viewer</h2>
        </div>
        {/* Accordion Items */}
        {[
          {
            id: 1,
            question: "What is Vercee?",
            answer:
              "Vercee is a virtual concert platform that allows you to experience live music events from the comfort of your home. We bring you high-quality performances from your favorite artists, streamed directly to your device."
          },
          {
            id: 2,
            question: "How does Vercee work?",
            answer:
              "Vercee partners with artists to create unique live performances that are streamed online. You can purchase a ticket for a Pay-Per-View event or subscribe to access a library of on-demand performances."
          },
          {
            id: 3,
            question: "How can I watch a concert on Vercee?",
            answer:
              "To watch a concert, simply browse our event listings, select the concert you’re interested in, and purchase a ticket. You can also subscribe to get access to our entire library of live and on-demand concerts."
          },
          {
            id: 4,
            question: "What makes Vercee different from other streaming platforms?",
            answer:
              "Vercee is specifically designed for live music performances, offering artists creative freedom to design unique shows. We handle all production aspects, so artists can focus on delivering an outstanding performance. For fans, Vercee provides interactive features, such as live chat and exclusive content, that enhance the overall experience beyond just watching a stream."
          },
          {
            id: 5,
            question: "What if i miss a concert?",
            answer:
              "If you miss a live concert, many performances are available as on-demand videos for users. You can enjoy the full concert experience anytime, as part of our on-demand library."
          },
          {
            id: 6,
            question: "Can I watch Vercee on multiple divices?",
            answer:
              "Yes, Vercee is accessible across various devices, including desktops, laptops, tablets, and smartphones. This allows fans to enjoy the concert experience wherever they are."
          },
          {
            id: 7,
            question: "How do I purchase tickets for a Vercee conscert?",
            answer:
              "Simply browse our event listings, select the concert you're interested in, and purchase a ticket via Pay-Per-View."
          },
          {
            id: 8,
            question: "Can I buy merchandise?",
            answer:
              "Yes, Vercee allows fans to purchase merchandise directly through the platform during and after concerts. Artists can link their merchandise stores to make it easy for fans to support them further."
          },
          {
            id: 9,
            question: "What happens is I experience technical issues during a live show?",
            answer:
              "Our support team is available to help with any technical issues that may arise during a performance. We also provide troubleshooting resources and may offer a replay option for viewers if an issue interrupts your viewing experience."
          },
          
        ].map(({ id, question, answer }) => (
          <div key={id} className="bg-deepPurple shadow-2xl w-full rounded-2xl">
            <div className="p-4 accordion-item">
              <button
                className="w-full text-left text-xl font-medium text-white text-shadow !shadow-none !hover:shadow-none"
                onClick={() => toggleAccordion(id)}
              >
                {question}
              </button>
            </div>
            <div
              id={`collapse${id}`}
              className={`overflow-hidden transition-all duration-300  rounded-b-2xl ease-in-out ${
                openId === id ? "max-h-96 p-4" : "max-h-0"
              } bg-white text-purple`}
            >
              {answer}
            </div>
          </div>
        ))}
        
      </div>
      <div className="w-full max-w-[1200px] p-2 space-y-4 mx-auto ">
        <div className="w-full relative text-center mt-6">
          <h2 className="text-4xl font-bold text-white text-shadow">Performers</h2>
        </div>
        {/* Accordion Items */}
        {[
          {
            id: 20,
            question: "Why should artists choose Vercee for virtual concerts?",
            answer:
              "Vercee provides a risk-free, high-quality platform tailored for artists who want to connect deeply with fans. We take care of the entire production process, ensuring that artists can deliver a memorable performance without financial risk."
          },
          {
            id: 21,
            question: "Does Vercee provide revenue-sharing opportunities for artists?",
            answer:
              "Yes, Vercee offers multiple revenue streams for artists, including PPV ticket sales, merchandise integration, and virtual meet-and-greet options. Our revenue-sharing model is designed to maximize income for artists without any production costs on their end."
          },
          {
            id: 22,
            question: "What are the benefits of performing on Vercee?",
            answer:
              "Vercee offers artists the ability to reach a global audience without the logistical challenges of touring. We handle the production, streaming, and even offer revenue-sharing opportunities from ticket sales and merchandise."
          },
          {
            id: 23,
            question: "What can fans expect from Vercee concert experience?",
            answer:
              "Fans can expect a high-quality, interactive concert experience that feels like a blend of live music, Broadway performances, and cinematic storytelling. With features like real-time chat, exclusive content, and virtual meet-and-greets, Vercee offers a unique, immersive way to enjoy concerts from anywhere."
          },
          {
            id: 24,
            question: "How do we handle artist payouts?",
            answer:
              "Vercee tracks all revenue generated through PPV tickets, subscriptions, and merchandise sales, and ensures that artists receive their share based on the agreed payout structure. Payments are made transparently and promptly, with detailed breakdowns provided."
          },
          {
            id: 25,
            question: "Does Vercee offer support for artists creative ideas?",
            answer:
              "Yes, Vercee works closely with artists and their teams to bring their creative vision to life. We support everything from set design and multi-angle views to storytelling elements, ensuring that artists have the creative freedom to produce a truly unique experience."
          },
          {
            id: 26,
            question: "Is Vercee suitable for all genres of music?",
            answer:
              "Vercee is designed to be flexible and inclusive, supporting a wide range of musical genres. Whether you’re a solo artist, band, or ensemble, Vercee can provide the tools and production support needed to deliver a top-quality performance."
          },
          {
            id: 27,
            question: "How do artists benefit from virtual concerts on Vercee?",
            answer:
              "Vercee gives artists a unique opportunity to reach fans globally, without the logistical challenges of a physical tour. Our virtual platform allows artists to engage with fans, earn revenue through various streams, and create memorable experiences without the high costs of traditional tours."
          },
          {
            id: 28,
            question: "Does Vercee integrate with social media?",
            answer:
              "While Vercee is a standalone platform, artists and fans are encouraged to share performances on social media. This helps increase visibility and creates a community of music lovers who can connect over shared experiences."
          }
        ].map(({ id, question, answer }) => (
          <div key={id} className="bg-deepPurple shadow-2xl w-full rounded-2xl">
            <div className="p-4 accordion-item">
              <button
                className="w-full text-left text-xl font-medium text-white text-shadow !shadow-none !hover:shadow-none"
                onClick={() => toggleAccordion(id)}
              >
                {question}
              </button>
            </div>
            <div
              id={`collapse${id}`}
              className={`overflow-hidden transition-all duration-300  rounded-b-2xl ease-in-out ${
                openId === id ? "max-h-96 p-4" : "max-h-0"
              } bg-white text-purple`}
            >
              {answer}
            </div>
          </div>
        ))}
        
      </div>
    </div>
    
  );
};

export default FAQ;
