import React from "react";
import VideoPlayer from "../components/VideoPlayer";
import { useParams } from "react-router-dom";

const VideoPlayerSetup = () => {
  const { videoId } = useParams();

 return (
   <div className="video-player-setup-page-container w-screen bg-gradient min-h-screen py-16 min-w-[320px] flex flex-col items-center justify-start">
     <VideoPlayer videoId={videoId} />
     <p>{videoId}</p>
   </div>
 );
};

export default VideoPlayerSetup;
