import React from "react";

const PPVPage = () => {
  return (
    <div className="ppv-page-container flex flex-col items-center">
      <header className="text-4xl font-bold decoration-und">PPV Page</header>
    </div>
  );
};

export default PPVPage;
