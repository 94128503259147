import React, { useState } from "react";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="w-full flex flex-row justify-center">
      <input
        type="text"
        className="p-2 border rounded-full text-black self-center h-3/4 w-full lg:max-w-[360px] xl:w-full xl:max-w-full"
        placeholder="Search videos, artists, or content..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
