import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-page-container w-screen bg-gradient min-h-screen p-8 pt-24 min-w-[320px]">
      <section className="relative text-center">
        <div className="w-full py-12">
          <h1 className="text-4xl font-bold text-white text-shadow">Privacy Policy</h1>
        </div>
      </section>
      <p className="py-8 text-xl underline">
        <strong>Effective Date:</strong> October 24, 2024
      </p>

      <p>
        Welcome to Vercee. We value your privacy and are committed to protecting your personal information. This Privacy
        Policy explains what information we collect, how we use it, and the measures we take to protect your information
        when you use our services.
      </p>

      <h2 className="mt-4">1. Introduction</h2>
      <p>
        We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and
        responsible manner. This Privacy Policy explains what information we collect, how we use it, and the measures we
        take to protect your information when you use our services.
      </p>

      <h2 className="mt-4">2. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you sign up for Vercee, we collect personal information such as
          your name, email address, payment details, and any other information you provide during registration or
          through your use of our services.
        </li>
        <li>
          <strong>Usage Information:</strong> We collect information about how you interact with our services, including
          the pages you visit, the features you use, and the time you spend on our platform.
        </li>
        <li>
          <strong>Device Information:</strong> We collect information about the device you use to access Vercee,
          including the type of device, operating system, browser type, and IP address.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to
          collect information about your browsing activities and improve your experience on our platform. You can manage
          your cookie preferences through your browser settings.
        </li>
      </ul>

      <h2 className="mt-4">3. How We Use Your Information</h2>
      <p>The information we collect is used to:</p>
      <ul>
        <li>Provide and maintain our services</li>
        <li>Personalize your experience on our platform</li>
        <li>Process your transactions and manage your account</li>
        <li>Communicate with you about updates, promotions, and other relevant information</li>
        <li>Improve the functionality and security of our platform</li>
      </ul>

      <h2 className="mt-4">4. Information Sharing and Disclosure</h2>
      <p>
        We do not sell, trade, or rent your personal information to third parties. However, we may share your
        information with:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with trusted third-party service providers
          who assist us in operating our platform, processing transactions, or providing customer support. These service
          providers are contractually obligated to keep your information confidential and use it only for the purposes
          for which we share it with them.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response
          to valid requests by public authorities (e.g., a court or government agency).
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our
          assets, your information may be transferred as part of that transaction. We will notify you of any such change
          in ownership or control of your personal information.
        </li>
      </ul>

      <h2 className="mt-4">5. Data Security</h2>
      <p>
        We implement a variety of security measures to protect your personal information. These measures include
        encryption, access controls, and secure data storage. While we strive to protect your information, no method of
        transmission over the Internet or electronic storage is completely secure, and we cannot guarantee its absolute
        security.
      </p>

      <h2 className="mt-4">6. Your Rights and Choices</h2>
      <ul>
        <li>
          <strong>Access and Update:</strong> You can access and update your personal information through your account
          settings.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us by following the
          unsubscribe instructions in those emails.
        </li>
        <li>
          <strong>Cookies:</strong> You can manage your cookie preferences through your browser settings.
        </li>
      </ul>

      <h2 className="mt-4">7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. When we do, we will revise the "Effective Date" at the top
        of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are
        protecting your information.
      </p>

      <h2 className="mt-4">8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:{" "}
        <a href="mailto:support@vercee.com">support@vercee.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
